<template>
  <div>
    <div class="el-icon-warning ontext" style="margin-bottom: 10px">
      手续费账单在订单结算后生成
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="{ background: '#F2F3F5' }"
      border
    >
      <el-table-column
        align="center"
        label="序号"
        type="index"
        :index="indexMethod"
        width="70"
      />
      <el-table-column
        prop="incomeExpenseType"
        label="收支类型"
        align="center"
      />
      <el-table-column prop="transactionType" label="交易类型" align="center" />
      <el-table-column
        prop="amount"
        label="平台手续费金额"
        align="center"
        width="120"
      />
      <el-table-column
        prop="transactionTime"
        label="交易时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="deliveryService"
        label="配送商"
        align="center"
        width="100"
      />
      <el-table-column
        prop="supplierDeliveryTime"
        label="配送商发货时间"
        align="center"
        width="170"
      />

      <el-table-column
        prop="originalOrderId"
        label="原订单号"
        align="center"
        width="170"
      />
      <el-table-column
        prop="originalGroupId"
        label="原主订单号"
        align="center"
        width="170"
      />
      <el-table-column
        prop="originalOrderGroupId"
        label="原订单批次号"
        align="center"
        width="170"
      />
      <el-table-column
        prop="paymentMethod"
        label="原单支付渠道"
        align="center"
        width="120"
      />
      <el-table-column
        prop="orderCreateTime"
        label="原订单下单时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="payTime"
        label="原订单支付时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="orderDeliveryTime"
        label="订单发货时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="settleFlag"
        label="原订单是否结算"
        align="center"
      />
      <el-table-column
        prop="settleTime"
        label="原订单结算时间"
        align="center"
        width="170"
      />
      <el-table-column
        prop="settlementBillNumber"
        label="结算账单号"
        align="center"
        width="170"
      />
      <el-table-column prop="separateMode" label="分账方式" align="center" />
      <el-table-column
        prop="whetherSeparateAccounts"
        label="原单是否分账"
        align="center"
      />
      <el-table-column
        prop="separateAccountFlow"
        label="分账流水编号"
        align="center"
      />
      <el-table-column prop="txNo" label="原单提现单号" align="center" />
      <el-table-column prop="remark" label="备注" align="center" />
      <el-table-column prop="" label="操作" align="center" width="150">
        <template slot-scope="{ row }">
          <div v-if="[' ', null, undefined].includes(row.separateAccountFlow)">
            -
          </div>
          <div
            class="btsed"
            v-else
            @click="$parent.upelectsd(row.separateAccountFlow)"
          >
            查看回单
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    queryParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    },
  },
};
</script>
<style lang="scss" scoped></style>
